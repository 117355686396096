












































































































@import '~variables'

.upload-modal
  >>> .modal-content
    display flex
    flex-direction column
    overflow hidden
    padding 16px
    width 900px
    .upload-modal__header
      display flex
      .informations
        flex-basis 40%
        padding-right 16px
        color black
        .informations__title
          margin 0
          margin-bottom 4px
          letter-spacing 0.4px
          font-weight 700
          font-size $fs-h1
          line-height 1
        .informations__subtitle
          margin 0
          font-size $fs-little
          line-height 1.3
      .upload-modal__input
        flex-basis 60%
        height 50px
    .loading-bar
      // margin 20px 0
    .upload__spinner-container
      display flex
      flex-direction column
      align-items center
      margin-top 8px
      padding 32px
      background-color $light
      .upload__spinner
        width 50px
        height @width
      .upload__spinner-message
        margin-top 10px
        text-align center
        font-weight 700
        font-size 1.3em
    .upload-modal__main
      display flex
      .upload-success
        display flex
        margin-top 8px
        padding 32px
        width 100%
        background $light
        .building-info
          width 60%
        .building-form
          width 40%
      .upload-error
        display flex
        flex 1
        justify-content center
        align-items center
        margin-top 8px
        padding 32px
        font-size 1.1em
        & > *
          margin 0 4px
        .upload-error__error-message
          font-weight 700
          font-size 1.3em
        .upload-error_doc-link
          text-decoration underline
          font-style italic
          cursor pointer
      .upload-modal__form
        flex-basis 40%
        margin-left auto
