@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: url("/fonts/Lato/Light/Lato-Light.ttf") format('truetype'), url("/fonts/Lato/Light/Lato-Light.woff2") format('woff2'), url("/fonts/Lato/Light/Lato-Light.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/Lato/Regular/Lato-Regular.ttf") format('truetype'), url("/fonts/Lato/Regular/Lato-Regular.woff2") format('woff2'), url("/fonts/Lato/Regular/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/Lato/Bold/Lato-Bold.ttf") format('truetype'), url("/fonts/Lato/Bold/Lato-Bold.woff2") format('woff2'), url("/fonts/Lato/Bold/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  src: url("/fonts/Lato/Black/Lato-Black.ttf") format('truetype'), url("/fonts/Lato/Black/Lato-Black.woff2") format('woff2'), url("/fonts/Lato/Black/Lato-Black.woff") format('woff');
}
.save-space-form {
  padding-left: 32px;
  border-left: 1px solid #464749;
}
.save-space-form .spinner-loader {
  stroke: #fff;
  fill: #fff;
}
.save-space-form .save-space-form__title {
  margin: 0;
  letter-spacing: 0.4px;
  font-weight: 700;
  font-size: 1.6rem;
}
.save-space-form .q-input.form-input--error >>> input {
  border: 1px solid #e91e63;
}
.save-space-form .grouped-field {
  display: flex;
}
.save-space-form .grouped-field .form-input--city {
  margin-right: 8px;
  width: 60%;
}
.save-space-form .grouped-field .form-input--zip {
  width: 37%;
}
.save-space-form .q-field >>> .q-field-bottom {
  padding-top: 0;
}
.save-space-form .q-field.q-field-with-error {
  margin-bottom: 0;
}
.save-space-form .q-input {
  margin: 0;
  padding: 0;
}
.save-space-form .q-input.q-if:before,
.save-space-form .q-input.q-if:after {
  content: none;
}
.save-space-form .q-input >>> .q-if-inner {
  padding-top: 20px;
}
.save-space-form .q-input >>> .q-if-label {
  top: 5px;
  left: 8px;
  color: #000;
  font-size: 1rem;
}
.save-space-form .q-input >>> .q-if-label-above {
  transform: scale(0.9) translate(-8px, -25px);
}
.save-space-form .q-input >>> input {
  margin: 0;
  padding: 16px 8px;
  border: 1px solid #464749;
  border-radius: 4px;
  color: #000;
  font-size: 1rem;
}
.save-space-form .form-create {
  margin-top: 16px;
  padding: 8px 16px;
  width: 100%;
  text-align: center;
  font-size: 1.1rem;
}
.save-space-form .skip-link {
  float: right;
  text-align: center;
  text-decoration: underline;
  font-size: 1rem;
  cursor: pointer;
}
.save-space-form--configuration .form-recap {
  display: flex;
  flex-direction: column;
  margin: 30px 0;
}
.save-space-form--configuration .form-recap .form-recap__item {
  height: 30px;
  font-size: 1.3rem;
}
.form-input__autocomplete {
  box-shadow: none;
}
.form-input__autocomplete >>> .q-list {
  padding: 0;
}
.form-input__autocomplete:hover >>> .q-list {
  background-color: #fff;
}
/*# sourceMappingURL=src/app/views/ifc-upload/form.css.map */