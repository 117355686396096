@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: url("/fonts/Lato/Light/Lato-Light.ttf") format('truetype'), url("/fonts/Lato/Light/Lato-Light.woff2") format('woff2'), url("/fonts/Lato/Light/Lato-Light.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/Lato/Regular/Lato-Regular.ttf") format('truetype'), url("/fonts/Lato/Regular/Lato-Regular.woff2") format('woff2'), url("/fonts/Lato/Regular/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/Lato/Bold/Lato-Bold.ttf") format('truetype'), url("/fonts/Lato/Bold/Lato-Bold.woff2") format('woff2'), url("/fonts/Lato/Bold/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  src: url("/fonts/Lato/Black/Lato-Black.ttf") format('truetype'), url("/fonts/Lato/Black/Lato-Black.woff2") format('woff2'), url("/fonts/Lato/Black/Lato-Black.woff") format('woff');
}
.file-input-form {
  display: flex;
  padding: 4px 0;
}
.file-input-form .file-input__container {
  flex: 1;
  border: 2px dashed #b4b2a9;
  border-radius: 4px;
  position: relative;
}
.file-input-form .file-input__container:hover {
  border-color: #f9c9cc;
}
.file-input-form .file-input__container:hover .file-input__label {
  color: #f7bbbf;
}
.file-input-form .file-input__container .file-input {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  opacity: 0;
  cursor: pointer;
}
.file-input-form .file-input__container .file-input__label {
  position: absolute;
  bottom: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #464749;
  pointer-events: none;
  font-size: 1.3em;
}
.file-input-form .file-input__button {
  margin-left: 16px;
  background-color: #f7bbbf;
  color: #fff;
  font-size: 1.2em;
  font-weight: 700;
  letter-spacing: 0.4px;
}
/*# sourceMappingURL=src/app/views/ifc-upload/file-input.css.map */