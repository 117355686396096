





















































































@import '~variables';

.file-input-form
  display flex
  padding 4px 0
  .file-input__container
    flex 1
    border 2px dashed $grey
    border-radius 4px
    position relative
    &:hover
      border-color lighten($primary, 20%)
      .file-input__label
        color $primary
    .file-input
      width 100%
      height 100%
      margin 0
      padding 0
      opacity 0
      cursor pointer
    .file-input__label
      position absolute
      bottom 0
      height 100%
      width 100%
      display flex
      justify-content center
      align-items center
      color $dark
      pointer-events none
      font-size 1.3em
  .file-input__button
    margin-left 16px
    background-color $primary
    color white
    font-size 1.2em
    font-weight 700
    letter-spacing 0.4px
