@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: url("/fonts/Lato/Light/Lato-Light.ttf") format('truetype'), url("/fonts/Lato/Light/Lato-Light.woff2") format('woff2'), url("/fonts/Lato/Light/Lato-Light.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/Lato/Regular/Lato-Regular.ttf") format('truetype'), url("/fonts/Lato/Regular/Lato-Regular.woff2") format('woff2'), url("/fonts/Lato/Regular/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/Lato/Bold/Lato-Bold.ttf") format('truetype'), url("/fonts/Lato/Bold/Lato-Bold.woff2") format('woff2'), url("/fonts/Lato/Bold/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  src: url("/fonts/Lato/Black/Lato-Black.ttf") format('truetype'), url("/fonts/Lato/Black/Lato-Black.woff2") format('woff2'), url("/fonts/Lato/Black/Lato-Black.woff") format('woff');
}
.loading-bar {
  width: 100%;
  transition: margin 0.3s ease;
  margin-top: 35px;
}
.loading-bar .bar-container {
  width: 100%;
  height: 30px;
  display: flex;
  background-color: #f3f1ef;
}
.loading-bar .bar-container .bar {
  position: relative;
  height: 100%;
  transition: width 0.3s ease;
  background: linear-gradient(to right, #f7bbbf 70%, #eb787f);
}
.loading-bar .bar-container .bar--in-progress {
  border-right: 4px solid #fff;
  box-shadow: 1px 0px 3px #b4b2a9;
}
.loading-bar .bar-container .bar-tooltip__container {
  position: absolute;
  top: -30px;
  right: -2px;
  z-index: 200;
}
.loading-bar .bar-container .bar-tooltip {
  position: relative;
  display: flex;
  align-items: center;
  padding: 4px 8px;
  border-radius: 18px;
  font-size: 0.8em;
  text-transform: uppercase;
  background-color: #464749;
  color: #fff;
}
.loading-bar .bar-container .bar-tooltip .bar-tooltip__loading-type {
  color: #f7bbbf;
  margin-right: 4px;
}
.loading-bar .bar-container .bar-tooltip:after {
  content: "";
  position: absolute;
  z-index: -1;
  width: 15px;
  height: 15px;
  background-color: #464749;
  right: 0px;
  bottom: 0px;
}
/*# sourceMappingURL=src/app/components/ui/loading-bar.css.map */