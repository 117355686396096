@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: url("/fonts/Lato/Light/Lato-Light.ttf") format('truetype'), url("/fonts/Lato/Light/Lato-Light.woff2") format('woff2'), url("/fonts/Lato/Light/Lato-Light.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/Lato/Regular/Lato-Regular.ttf") format('truetype'), url("/fonts/Lato/Regular/Lato-Regular.woff2") format('woff2'), url("/fonts/Lato/Regular/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/Lato/Bold/Lato-Bold.ttf") format('truetype'), url("/fonts/Lato/Bold/Lato-Bold.woff2") format('woff2'), url("/fonts/Lato/Bold/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  src: url("/fonts/Lato/Black/Lato-Black.ttf") format('truetype'), url("/fonts/Lato/Black/Lato-Black.woff2") format('woff2'), url("/fonts/Lato/Black/Lato-Black.woff") format('woff');
}
.building-info {
  display: flex;
  justify-content: center;
  padding-right: 32px;
}
.building-info .building-info__file-name {
  margin: 0 0 8px;
  font-weight: 700;
  font-size: 1.6rem;
}
.building-info .part-info {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.building-info .part-info .info-line {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
}
.building-info .part-info .info-line .info-line__label {
  font-size: 1.1rem;
}
.building-info .part-info .info-line .info-line__value {
  flex-basis: 20%;
  text-align: left;
  font-weight: 700;
  font-size: 1.1rem;
}
.building-info .part-info .info-line--big {
  margin-bottom: 0;
  padding: 16px 0;
  border-bottom: 1px solid #464749;
}
.building-info .part-info .info-line--big .info-line__label {
  font-weight: 500;
  font-size: 1.6rem;
}
.building-info .part-info .info-line--big .info-line__value {
  font-weight: 100;
  font-size: 1.6rem;
}
.building-info .part-info .other-info {
  margin-top: 16px;
}
.building-info .building__image {
  width: 130px;
}
/*# sourceMappingURL=src/app/views/ifc-upload/building-info.css.map */