

































































@import '~variables'

.building-info
  display flex
  justify-content center
  // align-items center
  padding-right 32px
  .building-info__file-name
    margin 0 0 8px
    font-weight 700
    font-size $fs-h1
  .part-info
    display flex
    flex-direction column
    width 100%
    .info-line
      display flex
      justify-content space-between
      margin-bottom 8px
      .info-line__label
        font-size $fs-h3
      .info-line__value
        flex-basis 20%
        text-align left
        font-weight 700
        font-size $fs-h3
    .info-line--big
      margin-bottom 0
      padding 16px 0
      border-bottom 1px solid $dark
      .info-line__label
        font-weight 500
        font-size $fs-h1
      .info-line__value
        font-weight 100
        font-size $fs-h1
    .other-info
      margin-top 16px
  .building__image
    width 130px
