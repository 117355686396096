@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: url("/fonts/Lato/Light/Lato-Light.ttf") format('truetype'), url("/fonts/Lato/Light/Lato-Light.woff2") format('woff2'), url("/fonts/Lato/Light/Lato-Light.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/Lato/Regular/Lato-Regular.ttf") format('truetype'), url("/fonts/Lato/Regular/Lato-Regular.woff2") format('woff2'), url("/fonts/Lato/Regular/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/Lato/Bold/Lato-Bold.ttf") format('truetype'), url("/fonts/Lato/Bold/Lato-Bold.woff2") format('woff2'), url("/fonts/Lato/Bold/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  src: url("/fonts/Lato/Black/Lato-Black.ttf") format('truetype'), url("/fonts/Lato/Black/Lato-Black.woff2") format('woff2'), url("/fonts/Lato/Black/Lato-Black.woff") format('woff');
}
.upload-modal >>> .modal-content {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 16px;
  width: 900px;
}
.upload-modal >>> .modal-content .upload-modal__header {
  display: flex;
}
.upload-modal >>> .modal-content .upload-modal__header .informations {
  flex-basis: 40%;
  padding-right: 16px;
  color: #000;
}
.upload-modal >>> .modal-content .upload-modal__header .informations .informations__title {
  margin: 0;
  margin-bottom: 4px;
  letter-spacing: 0.4px;
  font-weight: 700;
  font-size: 1.6rem;
  line-height: 1;
}
.upload-modal >>> .modal-content .upload-modal__header .informations .informations__subtitle {
  margin: 0;
  font-size: 0.8rem;
  line-height: 1.3;
}
.upload-modal >>> .modal-content .upload-modal__header .upload-modal__input {
  flex-basis: 60%;
  height: 50px;
}
.upload-modal >>> .modal-content .upload__spinner-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 8px;
  padding: 32px;
  background-color: #f3f1ef;
}
.upload-modal >>> .modal-content .upload__spinner-container .upload__spinner {
  width: 50px;
  height: 50px;
}
.upload-modal >>> .modal-content .upload__spinner-container .upload__spinner-message {
  margin-top: 10px;
  text-align: center;
  font-weight: 700;
  font-size: 1.3em;
}
.upload-modal >>> .modal-content .upload-modal__main {
  display: flex;
}
.upload-modal >>> .modal-content .upload-modal__main .upload-success {
  display: flex;
  margin-top: 8px;
  padding: 32px;
  width: 100%;
  background: #f3f1ef;
}
.upload-modal >>> .modal-content .upload-modal__main .upload-success .building-info {
  width: 60%;
}
.upload-modal >>> .modal-content .upload-modal__main .upload-success .building-form {
  width: 40%;
}
.upload-modal >>> .modal-content .upload-modal__main .upload-error {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  margin-top: 8px;
  padding: 32px;
  font-size: 1.1em;
}
.upload-modal >>> .modal-content .upload-modal__main .upload-error > * {
  margin: 0 4px;
}
.upload-modal >>> .modal-content .upload-modal__main .upload-error .upload-error__error-message {
  font-weight: 700;
  font-size: 1.3em;
}
.upload-modal >>> .modal-content .upload-modal__main .upload-error .upload-error_doc-link {
  text-decoration: underline;
  font-style: italic;
  cursor: pointer;
}
.upload-modal >>> .modal-content .upload-modal__main .upload-modal__form {
  flex-basis: 40%;
  margin-left: auto;
}
/*# sourceMappingURL=src/app/views/ifc-upload/modal.css.map */