












































































































































































































































@import '~variables'

.save-space-form
  padding-left 32px
  border-left 1px solid $dark
  .spinner-loader
    stroke white
    fill white
  .save-space-form__title
    margin 0
    letter-spacing 0.4px
    font-weight 700
    font-size $fs-h1
  .q-input.form-input--error
    >>> input
      border 1px solid $negative
  .grouped-field
    display flex
    .form-input--city
      margin-right 8px
      width 60%
    .form-input--zip
      width 37%
  .q-field
    >>> .q-field-bottom
      padding-top 0
  .q-field.q-field-with-error
    margin-bottom 0
  .q-input
    margin 0
    padding 0
    &.q-if:before, &.q-if:after
      content none
    >>> .q-if-inner
      padding-top 20px
    >>> .q-if-label
      top 5px
      left 8px
      color black
      font-size $fs-body
    >>> .q-if-label-above
      transform scale(0.9) translate(-8px, -25px)
    >>> input
      margin 0
      padding 16px 8px
      border 1px solid $dark
      border-radius 4px
      color black
      font-size $fs-body
  .form-create
    margin-top 16px
    padding 8px 16px
    width 100%
    text-align center
    font-size $fs-h3
  .skip-link
    float right
    text-align center
    text-decoration underline
    font-size $fs-body
    cursor pointer

.save-space-form--configuration
  .form-recap
    display flex
    flex-direction column
    margin 30px 0
    .form-recap__item
      height 30px
      font-size $fs-h2

.form-input__autocomplete
  box-shadow none
  >>> .q-list
    padding 0
  &:hover
    >>> .q-list
      background-color white
